// -*- coding: utf-8 -*-
import * as React from "react";

import logo from './logo.svg';
import './App.css';

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <a href="https://vea-tech.com/">
            <img src={logo} className="App-logo" alt="logo" />
          </a>
        </header>
      </div>
  );
}

export default App;
